import Decimal from 'decimal.js';

const usePointsPaySlider = (pointsPay, { min, max }) => {
  const start = min ? pointsPay.range.min : 0;
  const end = max ? pointsPay.range.max : 1;

  return {
    value: Decimal.div(
      Decimal.sub(pointsPay.state.value, start),
      Decimal.sub(end, start),
    ),
    setValue: (value) => {
      const newValue = Decimal.add(
        Decimal.mul(value, Decimal.sub(end, start)),
        start,
      );

      return pointsPay.setValue(newValue);
    },
  };
};

export default usePointsPaySlider;
