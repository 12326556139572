import Decimal from 'decimal.js';
import { useMemo, useState } from 'react';
import { convertCashToPoints, convertPointsToValue } from '@/lib/pointsPay';
import { roundDown, roundUp } from '@/lib/pointsPay/convertPointsToValue';

const usePointsPay = (initialValue, payable, tiers, { min, max }) => {
  const { amount } = payable;

  const [value, setValue] = useState(new Decimal(initialValue));

  const range = useMemo(
    () => ({
      min: Math.max(convertPointsToValue(min, tiers, amount, roundUp), 0),
      max: Math.min(convertPointsToValue(max, tiers, amount, roundDown), 1),
    }),
    [min, max, amount, tiers],
  );

  const correctedValue = useMemo(() => {
    if (value < range.min) {
      return range.min;
    }

    if (value > range.max) {
      return range.max;
    }

    return value;
  }, [value, range]);

  const state = useMemo(() => {
    const points = convertCashToPoints(
      Decimal.mul(amount, correctedValue),
      tiers,
    );
    const cash = Decimal.mul(amount, 1 - correctedValue).toDecimalPlaces(
      2,
      Decimal.ROUND_HALF_EVEN,
    );

    return {
      value: correctedValue,
      points,
      cash,
    };
  }, [amount, tiers, correctedValue]);

  const setCash = (newCash) => {
    const newValue = Decimal.div(Decimal.sub(amount, newCash), amount);
    setValue(newValue);
  };

  const setPoints = (newPoints) => {
    const newValue = convertPointsToValue(newPoints, tiers, amount, roundDown);
    setValue(newValue);
  };

  return {
    state,
    setCash,
    setPoints,
    setValue,
    range,
  };
};

export default usePointsPay;
