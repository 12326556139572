import { differenceInDays } from '@experiences-ui/shared/utils/date';

export const getNights = (searchQuery) => {
  const { departureDate, returnDate } = searchQuery;
  return differenceInDays(new Date(returnDate), new Date(departureDate));
};

const getStaySummaryText = (data, exact) => {
  // eslint-disable-next-line no-nested-ternary
  const nights = data ? (typeof data === 'object' ? getNights(data) : data) : 0;

  const valid = nights && nights > 0;

  return valid
    ? `Flights + ${nights} ${'night'} stay${exact && nights ? '' : ' from'}`
    : 'Flights + stay';
};

export default getStaySummaryText;
