import React from 'react';
import { Flex, Text } from '@qga/roo-ui/components';
import PropTypes from 'prop-types';
import Inclusion from '@/components/Inclusion';

const Inclusions = ({
  inclusions,
  variant,
  showSubtitle,
  truncateFourthInclusion,
  iconGap,
}) => (
  <>
    {inclusions?.map(({ code, name, subtitle }, index) => (
      <Flex key={index} mb="2" mr="5" data-testid="OFFER_INCLUSION">
        <Inclusion code={code} />
        <Flex flexDirection="column" ml={iconGap}>
          <Text fontSize={variant === 'base' ? 'base' : 'sm'}>
            {name}
            {truncateFourthInclusion && index === 3 && ' and more...'}
          </Text>
          {showSubtitle && subtitle && (
            <Text
              fontSize={variant === 'base' ? 'sm' : '2xs'}
              color="greys.steel"
            >
              {subtitle}
            </Text>
          )}
        </Flex>
      </Flex>
    ))}
  </>
);

Inclusions.defaultProps = {
  variant: 'sm',
  showSubtitle: false,
  truncateFourthInclusion: false,
  iconGap: '4',
};

Inclusions.propTypes = {
  inclusions: PropTypes.array.isRequired,
  variant: PropTypes.oneOf(['base', 'sm']),
  showSubtitle: PropTypes.bool,
  truncateFourthInclusion: PropTypes.bool,
  iconGap: PropTypes.string,
};

export default Inclusions;
