import { usePrice } from '@/v2/components/ui/Price/components/PriceContext';
import TotalPoints from '@/components/TotalPoints';
import { convertCashToPoints } from '@/lib/pointsPay';

const Points = () => {
  const { travellers, displayTravellerTypes, tiers, charges, showSummary } =
    usePrice() || {};

  if (!charges?.total?.amount || !travellers) {
    return null;
  }

  const points = convertCashToPoints(charges.total.amount, tiers).toNumber();

  return (
    <TotalPoints
      points={points}
      travellers={travellers}
      displayTravellerTypes={displayTravellerTypes}
      showSummary={showSummary}
    />
  );
};

export default Points;
