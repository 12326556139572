import { Text } from '@qga/roo-ui/components';
import PropTypes from 'prop-types';

const calculateSavings = (cashPrice, valuedPrice) => {
  return Math.floor(valuedPrice - cashPrice);
};

const isCashSaving = (total, valuation) => {
  const savings = calculateSavings(total?.amount, valuation?.amount);
  if (isNaN(savings)) {
    return null;
  }

  return !!valuation ? `Save $${savings.toLocaleString()}` : null;
};

const SavingsCvp = ({ total, valuation, ...rest }) => {
  return (
    <Text
      fontSize="sm"
      color={'darkGreen'}
      fontWeight={'bold'}
      textTransform={'uppercase'}
      data-testid={'savings-cvp'}
      {...rest}
    >
      {isCashSaving(total, valuation)}
    </Text>
  );
};

const numberOrString = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
  PropTypes.oneOf([null]),
]);

SavingsCvp.propTypes = {
  total: PropTypes.shape({
    amount: numberOrString,
  }).isRequired,
  valuation: PropTypes.shape({ amount: numberOrString }).isRequired,
};

export default SavingsCvp;
