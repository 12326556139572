import React from 'react';
import { Box, Text } from '@qga/roo-ui/components';
import Modal from '@/v2/components/ui/Modal';
import Divider from '@/v2/components/ui/Divider';
import ConditionsMarkdown from '@/v2/../components/ConditionsMarkdown';
import Inclusions from '@/v2/../shared/components/Inclusions';
import { Inclusion } from '../Inclusions/Inclusions';

export type PackageDetailsModalProps = {
  onClose: () => void;
  destinationName: string;
  propertyName: string;
  terms?: string;
  hotelTerms?: string;
  inclusions?: Inclusion[];
};
const PackageDetailsModal = ({
  onClose,
  destinationName,
  propertyName,
  inclusions,
  terms,
  hotelTerms,
}: PackageDetailsModalProps) => {
  return (
    <Modal onClose={onClose}>
      <Modal.Header>Package details and terms</Modal.Header>
      <Modal.Body>
        <Box p={5}>
          <Box fontSize="md" mb={4} data-testid="HOLIDAY-PACKAGE-TITLE">
            Holiday package to {destinationName} at {propertyName}
          </Box>
          {inclusions ? (
            <>
              <Box mb={3}>
                <Text fontSize="base">Package inclusions:</Text>
              </Box>
              <Inclusions inclusions={inclusions} showSubtitle />
            </>
          ) : (
            ''
          )}
          <Divider borderColor="colors.greys.alto" my={5} />
          <Box fontSize="sm">
            {terms && <ConditionsMarkdown markdown={terms} />}
            {hotelTerms && <ConditionsMarkdown markdown={hotelTerms} />}
          </Box>
        </Box>
      </Modal.Body>
    </Modal>
  );
};

export default PackageDetailsModal;
