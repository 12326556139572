import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Text } from '@qga/roo-ui/components';
import Travellers from '@/libs/propTypes/Travellers';
import Total from '@/propTypes/Total';
import formatMoney from '../../utils/formatMoney';
import getTravellersDescription from '../../utils/getTravellersDescription';
import SavingsCvp from '@/components/SavingsCvp';
import PriceText from '@/v2/components/ui/PriceText';
import styled from '@emotion/styled';

const StyledText = styled(Text)`
  align-self: center;
`;

const PricePerTraveller = ({
  total,
  perTraveller,
  travellers,
  displayTravellerTypes,
  exact,
  from,
  textAlign,
  valuedAt,
  showSummary = false,
}) => (
  <Flex
    data-testid="PRICE_PER_TRAVELLER"
    flexDirection="column"
    textAlign={textAlign}
  >
    <Flex flexDirection="row" textAlign="center">
      {showSummary && (
        <StyledText mr={1} fontSize={['xs', null, 'sm']}>
          Total
        </StyledText>
      )}
      <PriceText
        dataTestId="PER_PERSON_PRICE"
        prefix={<PriceText.Dollar />}
        suffix={showSummary ? <></> : <PriceText.PerPerson />}
      >
        {`${formatMoney(showSummary ? total.amount : perTraveller.amount, {
          wholeDollars: !exact,
          symbol: '',
        })}`}
      </PriceText>
    </Flex>
    {total && (
      <Text
        data-testid="TOTAL_PRICE"
        fontSize={['2xs', null, 'sm']}
        fontWeight="bold"
      >
        {!showSummary && (
          <>
            Total {from && 'from '}
            {formatMoney(total.amount, { wholeDollars: !exact })}
          </>
        )}

        {travellers &&
          ` for ${getTravellersDescription(travellers, displayTravellerTypes)}`}
      </Text>
    )}
    {valuedAt && !!valuedAt.amount && (
      <Flex>
        <Text fontSize={['2xs', null, 'sm']} color="greys.steel" mr={2}>
          Valued at {formatMoney(valuedAt.amount, { wholeDollars: !exact })}
        </Text>
        <SavingsCvp
          fontSize={['2xs', null, 'sm']}
          total={total}
          valuation={valuedAt}
        />
      </Flex>
    )}
  </Flex>
);

PricePerTraveller.defaultProps = {
  total: undefined,
  travellers: undefined,
  displayTravellerTypes: false,
  exact: false,
  from: false,
  textAlign: 'left',
  valuedAt: undefined,
};

PricePerTraveller.propTypes = {
  perTraveller: PropTypes.shape(Total),
  total: PropTypes.shape(Total),
  travellers: PropTypes.shape(Travellers),
  displayTravellerTypes: PropTypes.bool,
  exact: PropTypes.bool,
  from: PropTypes.bool,
  textAlign: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  valuedAt: PropTypes.shape(Total),
};

export default PricePerTraveller;
