import { Box } from '@qga/roo-ui/components';
import React, { ComponentProps, FunctionComponent, ReactNode } from 'react';
import styled from '@emotion/styled';

const StyledBody = styled(Box)`
  overflow-y: auto;
`;

interface Props extends ComponentProps<typeof Box> {
  children: ReactNode;
}

const Body: FunctionComponent<Props> = ({ children, ...props }) => (
  <StyledBody {...props}>{children}</StyledBody>
);

export default Body;
