import { Box, Flex, Heading, Icon, NakedButton } from '@qga/roo-ui/components';
import React, { PropsWithChildren } from 'react';
import ModalContext from '../../ModalContext';

interface Props extends PropsWithChildren<{}> {}

const Header = ({ children }: Props) => (
  <ModalContext.Consumer>
    {({ onClose }) => (
      <Box p="5" borderBottom="1" borderColor="greys.alto" position="relative">
        <Heading.h6
          data-testid="MODAL_HEADER"
          fontSize="md"
          mb="0"
          textAlign="center"
        >
          {children}
        </Heading.h6>
        <Flex position="absolute" right="0" top="0" bottom="0" pr="4">
          <NakedButton
            aria-label="Close"
            data-testid="CLOSE_BUTTON"
            onClick={() => onClose()}
          >
            <Icon name="close" />
          </NakedButton>
        </Flex>
      </Box>
    )}
  </ModalContext.Consumer>
);

export default Header;
