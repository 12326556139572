import { useAppSettings } from '@/components/AppSettingsProvider';
import { useApp } from '@/v2/contexts/app';
import { QFFPointTiersInstance } from '@/v2/services/points/types/api/parsedResponse';
import qffHotelsTiers from '@/data/qffHotelsPointTiers';

type UseDefaultTiersProps = {
  useLuxuryTiers?: boolean;
  tiersOverride?: QFFPointTiersInstance;
} | void;

const enrichWithInfinity = (qffPointTiers?: QFFPointTiersInstance) => {
  const tiers = [...(qffPointTiers?.tiers || [])];
  const lastTier = tiers.pop();

  if (!lastTier) {
    return qffPointTiers;
  }

  lastTier.max = lastTier.max || Number.POSITIVE_INFINITY;

  return {
    ...qffPointTiers,
    tiers: [...tiers, lastTier],
  };
};

/**
 * Requires `App` or `AppSettings` context.
 * @description - Provides the default points tier settings with the ability to
 * override the defaults.
 * NOTE: Override should be used on package page with data from *offers* endpoint.
 * @param props.tiersOverride - Override default tiers with custom tiers (e.g. from offers endpoint)
 * @param props.useLuxuryTiers - Use the 'luxury' variant of default tiers (e.g. for home page).
 */
const useDefaultPointTiers = (props: UseDefaultTiersProps) => {
  const { tiersOverride, useLuxuryTiers } = props || {};
  const appSettings = useAppSettings(); // Old app settings context
  const app = useApp(); // V2 app settings context

  const appTiers =
    app?.qffPointTiers?.holidaysPointsTiers ||
    // @ts-ignore - JS context doesn't have full type def
    appSettings?.qffPointTiers?.holidaysPointsTiers;

  const luxuryTiers =
    app?.qffPointTiers?.luxuryPointsTiers ||
    // @ts-ignore - JS context doesn't have full type def
    appSettings?.qffPointTiers?.luxuryPointsTiers ||
    qffHotelsTiers;

  const qffPointTiers =
    tiersOverride || (useLuxuryTiers ? luxuryTiers : appTiers);

  return enrichWithInfinity(qffPointTiers)?.tiers;
};

export default useDefaultPointTiers;
