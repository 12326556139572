import { useCustomerSelection } from '@/components/CustomerSelectionProvider';
import { PriceProps } from './types';
import useDefaultPointTiers from '@/v2/hooks/useDefaultPointTiers';
import {
  Cash,
  PriceProvider,
  Points,
  PointsSlider,
  PointsPlusPay,
} from './components';

/**
 * @description - A compound component for displaying a price based off user's pay selection.
 * Data provided to `Price` component is passed down to subcomponents via context.
 * Custom components can access the values via `usePrice` hook.
 *
 * Includes subcomponents:
 *  - Price.Cash - Default price component in dollars.
 *  - Price.Points - Price component converted into Qantas Points.
 *  - Price.PointsSlider - Price input component for changing portion between points and cash.
 *
 * Requires `AppContext` or `AppSettingsContext` if not providing `tiersOverride`.
 * Requires `CustomerSelectionContext` for user payment selection.
 *
 *  @example - Display points only (no points + pay):
 *     <Price
 *       // {...} Other props
 *       cash={<Price.Cash />}
 *       points={<Price.Points />}
 *       pointsPlusPay={<Price.Points />}
 *     />
 *  @example - Display points slider when points and pay is selected:
 *     <Price
 *       // {...} Other props
 *      cash={<Price.Cash />}
 *      points={<Price.Points />}
 *      pointsPlusPay={<Price.PointsSlider />}
 *     />
 *  @example - Completely custom components
 *     <Price
 *       // {...} Other props
 *       cash={<MyCustomCash />} // Can access data provided to `Price` via `usePrice`
 *       points={<MyCustomPoints />}
 *       pointsPlusPay={<MyCustomPointsAndPay />}
 *     />
 * @param tiersOverride - Override the default app settings points tiers (e.g. tiers return from
 * `offers` endpoint on package page).
 * @param useLuxuryTiers - use the default 'luxury' variant of points tiers (e.g. for home page)
 * @param charges - Charges object for the price/s to display (total, perTraveller, etc.).
 * @param travellers - Breakdown of travellers (adults, infants, children)
 * @param displayTravellerTypes - Flag for showing traveller type.
 * @param offerId - unique ID for the offer, used for `Price.PointsSlider` to persist user selection.
 * @param exact - Flag for showing the exact cash price.
 * @param from - Flag to include 'from' text in cash price.
 * @param cash - Element/s to render when user has selected 'cash' payment option.
 * @param points -  Element/s to render when user has selected 'points' payment option.
 * @param pointsPlusPay -  Element/s to render when user has selected 'points + pay' payment option.
 */
const Price = ({
  tiersOverride,
  useLuxuryTiers,
  charges,
  travellers,
  displayTravellerTypes,
  offerId = '',
  exact,
  from,
  // elements to render
  cash,
  points,
  pointsPlusPay,
  showSummary,
}: PriceProps) => {
  const { usePoints, usePointsPlusPay } = useCustomerSelection();
  const pointTiers = useDefaultPointTiers({
    tiersOverride,
    useLuxuryTiers,
  });

  return (
    <PriceProvider
      value={{
        tiers: pointTiers,
        travellers,
        charges,
        offerId,
        displayTravellerTypes,
        exact,
        from,
        showSummary,
      }}
    >
      {!usePoints && cash}
      {usePoints && !usePointsPlusPay && points}
      {usePoints && usePointsPlusPay && pointsPlusPay}
    </PriceProvider>
  );
};

// Sub-components
Price.Cash = Cash;
Price.Points = Points;
Price.PointsSlider = PointsSlider;
Price.PointsPlusPay = PointsPlusPay;

export default Price;
