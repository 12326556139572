import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Box, NakedButton } from '@qga/roo-ui/components';
import { themeGet, height, width, left } from 'styled-system';
import withProps from '../withProps/withProps';
import throttle from '@experiences-ui/shared/v2/utils/throttle';

const Rail = withProps({
  position: 'absolute',
  top: '50%',
  width: '100%',
  height: '3px',
})(styled(Box)`
  transform: translateY(-50%);
  background: linear-gradient(
    to right,
    ${themeGet('colors.brand.primary')} ${(props) => props.percentage},
    ${themeGet('colors.greys.alto')} ${(props) => props.percentage}
  );
`);

const Handler = withProps({
  bg: 'brand.primary',
})(styled(NakedButton)`
  position: absolute;
  border: 1px solid ${themeGet('colors.white')};
  border-radius: 50%;
  cursor: grab;
  transform: translateX(-50%);

  ${height}
  ${width}
  ${left}
  
  :focus {
    outline: none;
    box-shadow:
      0 0 0 1px ${themeGet('colors.white')},
      0 0 0 3px ${themeGet('colors.ui.info')};
  }
`);

const SliderInput = ({ value, onChange }) => {
  const percentageValue = `${value * 100}%`;
  const ref = useRef(false);

  const register = ({ target: element }) => {
    if (ref.current) {
      return;
    }

    ref.current = true;

    const containerWidth = element.parentElement.offsetWidth;
    const rect = element.parentElement.getBoundingClientRect();
    const offsetX = rect.x || rect.left;

    const onMove = (x) => {
      let newValue = x / containerWidth;

      if (newValue < 0) {
        newValue = 0;
      }

      if (newValue > 1) {
        newValue = 1;
      }

      onChange(newValue);
    };

    const onTouchMove = throttle((event) => {
      event.preventDefault();
      const x = event.changedTouches[0].clientX - offsetX;

      onMove(x);
    });

    const onMousemove = throttle((event) => {
      event.preventDefault();

      const x = event.clientX - offsetX;

      onMove(x);
    });

    const onKeydown = throttle((event) => {
      const { offsetLeft } = element;
      const step = 0.01 * containerWidth;

      switch (event.keyCode) {
        case 37: // LEFT
        case 40: // DOWN
          event.preventDefault();
          onMove(offsetLeft - step);
          break;

        case 38: // UP
        case 39: // RIGHT
          event.preventDefault();
          onMove(offsetLeft + step);
          break;

        default:
      }
    });

    const onMouseUp = () => {
      element.blur();
    };

    const onCleanup = () => {
      ref.current = false;

      element.removeEventListener('blur', onCleanup);

      window.removeEventListener('mousemove', onMousemove);
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('mouseup', onCleanup);

      window.removeEventListener('touchmove', onTouchMove);
      window.removeEventListener('touchend', onCleanup);

      window.removeEventListener('keydown', onKeydown);
    };

    element.addEventListener('blur', onCleanup);

    window.addEventListener('mousemove', onMousemove);
    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('mouseup', onCleanup);

    window.addEventListener('touchmove', onTouchMove);
    window.addEventListener('touchend', onCleanup);

    window.addEventListener('keydown', onKeydown);
  };

  return (
    <Box height="65px" position="relative">
      <Rail percentage={percentageValue} />
      <Box mx="2" position="relative">
        <Handler
          height="18px"
          width="18px"
          my="23px"
          data-testid="HANDLER"
          left={percentageValue}
          onTouchStart={register}
          onMouseDown={register}
          onFocus={register}
          aria-label="slider"
        />
      </Box>
    </Box>
  );
};

SliderInput.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SliderInput;
