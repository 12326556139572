import PropTypes from 'prop-types';
import React from 'react';
import { Icon, Image } from '@qga/roo-ui/components';
import getIconNameFromCode from './utils/getIconNameFromCode';
import getImageFromCode from './utils/getImageFromCode';

const Inclusion = ({ code }) => {
  const image = getImageFromCode(code);

  if (!image) {
    const iconName = getIconNameFromCode(code);

    return <Icon name={iconName} />;
  }

  return <Image src={image} alt={code} />;
};

Inclusion.propTypes = {
  code: PropTypes.string.isRequired,
};

export default Inclusion;
