import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '@qga/roo-ui/components';
import getStaySummaryText from '@/utils/getStaySummaryText';
import withProps from '@/shared/components/withProps';
import SearchQuery from '../../propTypes/SearchQuery';
import Summary from '../Summary';
import styled from '@emotion/styled';
import { SCREEN_SIZE } from '@/lib/theme';

const Prefix = withProps({
  size: '16px',
  name: 'flightRight',
  size: [16, null, 24],
})(Icon);

const StyledSummary = styled(Summary)`
  line-height: 1;

  & > span {
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
  }

  ${SCREEN_SIZE.SM} {
    & > span {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

const PackageHeroSummary = ({ searchQuery, exact, showNights, nights }) => {
  if (!showNights) {
    return <StyledSummary text="Flights + hotel" prefix={<Prefix />} />;
  }

  return (
    <StyledSummary
      text={getStaySummaryText(
        searchQuery?.returnDate ? searchQuery : nights,
        exact,
      )}
      prefix={<Prefix />}
    />
  );
};

PackageHeroSummary.defaultProps = {
  searchQuery: undefined,
  exact: false,
  showNights: false,
  nights: undefined,
};

PackageHeroSummary.propTypes = {
  searchQuery: PropTypes.shape(SearchQuery),
  exact: PropTypes.bool,
  showNights: PropTypes.bool,
  nights: PropTypes.number,
};

export default PackageHeroSummary;
