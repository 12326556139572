import { PriceDataProps } from '@/v2/components/ui/Price/types';
import Price from '@/v2/components/ui/Price/Price';

const PriceCashOnly = (props: PriceDataProps) => {
  return (
    <Price
      {...props}
      cash={<Price.Cash />}
      points={<Price.Cash />}
      pointsPlusPay={<Price.Cash />}
    />
  );
};

const PriceCashAndPointsOnly = (props: PriceDataProps) => {
  return (
    <Price
      {...props}
      cash={<Price.Cash />}
      points={<Price.Points />}
      pointsPlusPay={<Price.Points />}
    />
  );
};

const PriceWithPointsPlusPaySlider = (props: PriceDataProps) => {
  return (
    <Price
      {...props}
      cash={<Price.Cash />}
      points={<Price.Points />}
      pointsPlusPay={<Price.PointsSlider />}
    />
  );
};

const PriceWithPointsPlusPay = (props: PriceDataProps) => {
  return (
    <Price
      {...props}
      cash={<Price.Cash />}
      points={<Price.Points />}
      pointsPlusPay={<Price.PointsPlusPay />}
    />
  );
};

const PriceWithCompactPointsPlusPay = (props: PriceDataProps) => {
  return (
    <Price
      {...props}
      cash={<Price.Cash />}
      points={<Price.Points />}
      pointsPlusPay={<Price.PointsPlusPay compact />}
    />
  );
};

export {
  PriceCashOnly,
  PriceCashAndPointsOnly,
  PriceWithPointsPlusPaySlider,
  PriceWithPointsPlusPay,
  PriceWithCompactPointsPlusPay,
};
