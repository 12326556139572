import { usePrice } from './PriceContext';
import PricePerTraveller from '@/components/PricePerTraveller';

const Cash = () => {
  const {
    travellers,
    charges,
    from,
    exact,
    displayTravellerTypes,
    showSummary,
  } = usePrice() || {};

  if (!travellers || !charges?.perTraveller) {
    return null;
  }

  return (
    <PricePerTraveller
      perTraveller={charges?.perTraveller}
      total={charges?.total}
      valuedAt={charges?.valuedAtTotal}
      travellers={travellers}
      displayTravellerTypes={displayTravellerTypes}
      from={from}
      exact={exact}
      showSummary={showSummary}
    />
  );
};

export default Cash;
