const parsePoints = (pointsString: string) => {
  const parts = pointsString.split('_');
  return parts[1] ? parts[0] + '_' + Number(parts[1]).toFixed(1) : parts[0];
};

const getSafePointsStoredValue = (
  points: { [key: string]: number },
  offerId: string,
  amount: string | number,
) => {
  const parsedPoints = Object.fromEntries(
    Object.entries(points).map(([key, value]) => [parsePoints(key), value]),
  );

  return parsedPoints[`${offerId}_${Number(amount).toFixed(1)}`];
};

export default getSafePointsStoredValue;
