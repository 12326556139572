import React from 'react';

export interface ModalContextProps {
  onClose: Function;
}

const ModalContext = React.createContext<ModalContextProps>({
  onClose: () => {},
});

export default ModalContext;
