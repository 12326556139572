import { QFFPointTiersInstance } from '@/v2/services/points/types/api/parsedResponse';
import { convertCashToPoints } from '@/lib/pointsPay';
import Decimal from 'decimal.js';

export type SplitPointsPlusPayAmountProps = {
  tiers: QFFPointTiersInstance['tiers'];
  amount: string | number;
  fractionAsPoints: number;
};
const splitPointsPlusPayAmount = ({
  tiers,
  amount,
  fractionAsPoints,
}: SplitPointsPlusPayAmountProps) => {
  if (!tiers || amount == null || fractionAsPoints == null) {
    return {
      cash: null,
      points: null,
    };
  }

  const points = convertCashToPoints(
    Decimal.mul(amount, fractionAsPoints),
    tiers,
  ).toNumber();

  const cash = Decimal.mul(amount, 1 - fractionAsPoints)
    .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
    .toNumber();

  return {
    cash,
    points,
  };
};

export default splitPointsPlusPayAmount;
