import beachEscapes from './beach_escapes.svg';
import dinnerincluded from './dinnerincluded.svg';
import earlycheckin from './earlycheckin.svg';
import islandEscape from './island_escape.svg';
import latecheckout from './latecheckout.svg';
import minibar from './minibar.svg';
import spatreatment from './spatreatment.svg';
import tourincluded from './tourincluded.svg';
import valetparking from './valetparking.svg';
import welcomedrink from './welcomedrink.svg';
import welcomegift from './welcomegift.svg';

export default {
  beach_escapes: beachEscapes,
  island_escape: islandEscape,
  early_checkin: earlycheckin,
  late_checkout: latecheckout,
  valet_parking: valetparking,
  dinner: dinnerincluded,
  mini_bar: minibar,
  welcome_drink: welcomedrink,
  welcome_gift: welcomegift,
  spa_treatment: spatreatment,
  tour: tourincluded,
};
