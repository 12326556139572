import { min } from 'ramda';
import { useCustomerSelection } from '@/components/CustomerSelectionProvider';
import { useAuth } from '@/components/Auth';
import { DEFAULT_OFFER_POINTS } from '@/constants';
import convertPointsToValue, {
  roundDown,
} from '@/lib/pointsPay/convertPointsToValue';
import getSafePointsStoredValue from '@/utils/getSafePointsStoredValue';

const useDefaultPoints = (offerId, total, tiers) => {
  const { memberDetails } = useAuth();
  const { points } = useCustomerSelection();
  const { amount } = total;
  const defaultPoints = memberDetails
    ? min(Number(memberDetails.points), DEFAULT_OFFER_POINTS)
    : DEFAULT_OFFER_POINTS;

  const storedValue =
    points && getSafePointsStoredValue(points, offerId, amount);
  const defaultValue = convertPointsToValue(
    defaultPoints,
    tiers,
    amount,
    roundDown,
  ).toNumber();

  return {
    value: storedValue || defaultValue,
  };
};

export default useDefaultPoints;
