import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Box, Flex, Icon, Text } from '@qga/roo-ui/components';
import accounting from 'accounting';
import Travellers from '@/libs/propTypes/Travellers';
import Tier from '@/propTypes/Tier';
import SliderInput from '@/components/SliderInput';
import Input from '@/components/Input';
import { useCustomerSelection } from '@/components/CustomerSelectionProvider';
import { useAuth } from '@/components/Auth';
import LinkButton from '@/components/LinkButton';
import { PAY_WITH_POINTS_MIN } from '@/constants';
import {
  usePointsPay,
  usePointsPayInput,
  usePointsPaySlider,
} from '@/hooks/pointsPay';
import getTravellersDescription from '@/utils/getTravellersDescription';

const PointsOfferSlider = ({
  id,
  tiers,
  payable,
  boundary,
  initialState,
  travellers,
  displayTravellerTypes,
}) => {
  const initialValue = initialState ? initialState.value : 1;
  const pointsOffer = usePointsPay(initialValue, payable, tiers, boundary);

  const { value, setValue } = usePointsPaySlider(pointsOffer, {
    min: true,
    max: false,
  });

  const {
    cash,
    onCashChange,
    onCashFocus,
    onCashBlur,
    points,
    onPointsChange,
    onPointsFocus,
    onPointsBlur,
  } = usePointsPayInput(pointsOffer);

  const { setPoints } = useCustomerSelection();
  const { memberDetails, login } = useAuth();

  const pointsAriaId = `${id}-points`;
  const cashAriaId = `${id}-cash`;

  const hasExeededPoints =
    memberDetails && accounting.unformat(points) > memberDetails.points;

  const setPointsToUserMaximum = () =>
    pointsOffer.setPoints(memberDetails.points);

  useEffect(() => {
    setPoints((prevPoints) => ({
      ...prevPoints,
      [id]: +pointsOffer.state.value,
    }));
  }, [id, pointsOffer.state.value, setPoints]);

  return (
    <Flex flexDirection="column" mt={4} p="3" bg="greys.porcelain">
      <Box display={['block', null, 'flex']} pb={3}>
        <Flex alignItems="center">
          <Icon name="roo" title="roo icon" color="brand.primary" mr={2} />
          <Text color="greys.charcoal" fontWeight="bold" fontSize="sm">
            Use points
          </Text>
        </Flex>
      </Box>
      <Flex justifyContent="space-between" alignItems="center">
        <Input
          prefix={<label htmlFor={pointsAriaId}>PTS</label>}
          fontWeight="bold"
          fontSize={['sm', null, 'base']}
          id={pointsAriaId}
          textAlign="right"
          onFocus={onPointsFocus}
          onBlur={onPointsBlur}
          onChange={onPointsChange}
          value={points}
        />
        <Text fontWeight="bold" mx={['1', null, '2']}>
          +
        </Text>
        <Input
          prefix={<label htmlFor={cashAriaId}>$</label>}
          fontWeight="bold"
          fontSize={['sm', null, 'base']}
          id={cashAriaId}
          textAlign="right"
          onFocus={onCashFocus}
          onBlur={onCashBlur}
          onChange={onCashChange}
          value={cash}
        />
      </Flex>
      <Text pt={2} fontSize="sm" fontWeight="bold">
        {`Total for ${getTravellersDescription(
          travellers,
          displayTravellerTypes,
        )}`}
      </Text>
      <SliderInput value={value.toNumber()} onChange={setValue} />
      <Text fontSize="xs" color="greys.steel" pb="2">
        {hasExeededPoints && (
          <Box fontWeight="bold" fontSize="xs" mb="1">
            You&apos;ve exceeded your total points balance.
          </Box>
        )}
        {memberDetails ? (
          <>
            <LinkButton onClick={setPointsToUserMaximum}>Set slider</LinkButton>{' '}
            to maximum points.
          </>
        ) : (
          <>
            <LinkButton onClick={login}>Log in</LinkButton> to see your balance
            and use Qantas Points.
          </>
        )}{' '}
        Minimum {accounting.formatNumber(PAY_WITH_POINTS_MIN)} PTS required.
      </Text>
    </Flex>
  );
};

PointsOfferSlider.defaultProps = {
  initialState: undefined,
  displayTravellerTypes: false,
};

PointsOfferSlider.propTypes = {
  id: PropTypes.string.isRequired,
  tiers: PropTypes.arrayOf(PropTypes.shape(Tier)).isRequired,
  boundary: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }).isRequired,
  payable: PropTypes.any.isRequired,
  initialState: PropTypes.shape({
    value: PropTypes.number,
  }),
  travellers: PropTypes.shape(Travellers).isRequired,
  displayTravellerTypes: PropTypes.bool,
};

export default PointsOfferSlider;
