import PriceText from '@/v2/components/ui/PriceText';
import { formatNumber } from 'accounting';
import { Flex, Text } from '@qga/roo-ui/components';
import formatMoney from '@/utils/formatMoney';
import getTravellersDescription from '@/utils/getTravellersDescription';
import React from 'react';
import styled from '@emotion/styled';

export type PointsPlusPayProps = {
  points: number;
  cash: number;
  travellers?: {
    adults?: string | number | null;
    children?: string | number | null;
    infants?: string | number | null;
  };
  displayTravellerTypes?: boolean;
  compact?: boolean;
  showSummary?: boolean;
};

const PriceFlex = styled(Flex)`
  flex-wrap: wrap;
  column-gap: 0.25rem;
`;

const PointsPlusPay = ({
  points,
  cash,
  travellers,
  displayTravellerTypes,
  compact,
  showSummary,
}: PointsPlusPayProps) => {
  return (
    <Flex flexDirection="column">
      <PriceFlex data-testid="POINTS_PLUS_PAY_AMOUNT">
        <PriceText
          compact={compact}
          suffix={<PriceText.Pts compact={compact} />}
        >
          {formatNumber(points)}
        </PriceText>
        <Text
          fontWeight="bold"
          mt="1"
          fontSize={compact ? '2xs' : ['2xs', null, 'sm']}
          alignSelf="center"
        >
          +
        </Text>
        <PriceText
          compact={compact}
          prefix={<PriceText.Dollar compact={compact} />}
        >
          {formatMoney(cash, { symbol: '' })}
        </PriceText>
      </PriceFlex>
      {travellers && (
        <Text fontSize={compact ? '2xs' : 'sm'} fontWeight="bold">
          {!showSummary && 'Total '}
          {`for ${getTravellersDescription(travellers, displayTravellerTypes)}`}
        </Text>
      )}
    </Flex>
  );
};

export default PointsPlusPay;
