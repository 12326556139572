const getIconNameFromCode = (code = '') =>
  ({
    destination: 'place',
    internet: 'wifi',
    parking: 'localParking',
    breakfast: 'freeBreakfast',
    kids_stay_free: 'accessibility',
    upgrade: 'arrowUpward',
    club_access: 'localPlay',
    kids_eat_free: 'restaurant',
    transfers: 'directionsBus',
    late_checkout: 'accessTime',
    spa_voucher: 'spa',
    bottle_of_wine: 'localBar',
    points: 'qffPoints',
    flights: 'flightRight',
    statusCredits: 'stars',
    pointsPay: 'pointsPay',
    bucketlist: 'checkBox',
    family_friendly: 'childCare',
    family_stays: 'childCare',
    family_stay: 'childCare',
    city_breaks: 'locationCity',
    city_break: 'locationCity',
    romantic_getaways: 'favorite',
    romantic_getaway: 'favorite',
    romantic: 'favorite',
    indulgence: 'spa',
    indulgent: 'spa',
    spa_resort: 'spa',
    weekend_escapes: 'event',
    weekend_escape: 'event',
    adults_only: 'people',
    adult_only: 'people',
    all_inclusive: 'allInclusive',
    boutique: 'roomService',
    boutique_hotel: 'roomService',
    midweek_break: 'dateRange',
    short_break: 'eventAvailable',
    long_stay: 'eventNote',
    regional_escape: 'terrain',
    food_and_wine: 'localDining',
    shopping: 'localMall',
    experience: 'naturePeople',
    beachfront: 'beachAccess',
    beach_escape: 'beachAccess',
    beach_escapes: 'beachAccess',
    special_event: 'localActivity',
    apartment_style: 'locationCity',
    apartment_stay: 'locationCity',
    apartment_stays: 'locationCity',
    apartment_style_stay: 'locationCity',
    luxury: 'star',
    // Material Design Icons (mdi)
    schedule: 'schedule',
    directions_car: 'directionsCar',
    hotel: 'hotel',
    local_activity: 'localActivity',
    local_offer: 'localOffer',
    place: 'place',
    restaurant: 'restaurant',
    check: 'done',
    local_cafe: 'freeBreakfast',
    spa: 'spa',
    wine_bar: 'wineBar',
    card_giftcard: 'cardGiftcard',
    face: 'face',
    liquor: 'liquor',
    wifi: 'wifi',
    stars: 'stars',
    flag: 'flag',
    camera_alt: 'cameraAlt',
    directions_bus: 'directionsBus',
    sailing: 'sailing',
    beach_access: 'beachAccess',
    child_friendly: 'childFriendly',
    golf_course: 'golfCourse',
    hot_tub: 'hotTub',
    outline_free_cancellation: 'freeCancellation',
  })[code] || 'done';

export default getIconNameFromCode;
