import { usePrice } from './PriceContext';
import PointsOfferSlider from '@/components/PackagePage/components/RoomOptions/components/RoomOption/components/Offers/components/Offer/components/CallToAction/components/OfferCTA/components/Price/components/PointsOfferSlider';
import { BOUNDARY } from '@/constants';
import useDefaultPoints from '@/hooks/pointsPay/useDefaultPoints';

const PointsSlider = () => {
  const { offerId, charges, displayTravellerTypes, travellers, tiers } =
    usePrice() || {};

  const defaultPoints = useDefaultPoints(offerId, charges?.total, tiers) ?? {
    value: 0.5,
  };

  if (!charges || !tiers || !travellers) {
    return null;
  }

  return (
    <PointsOfferSlider
      id={`${offerId}_${charges?.total?.amount}`}
      tiers={tiers}
      payable={charges.total}
      boundary={BOUNDARY}
      initialState={defaultPoints}
      travellers={travellers}
      displayTravellerTypes={displayTravellerTypes}
    />
  );
};

export default PointsSlider;
