import React, { FunctionComponent } from 'react';
import { Global } from '@emotion/core';
import { Flex } from '@qga/roo-ui/components';
import Portal from '@experiences-ui/shared/v2/components/Portal';

import ModalContext from './ModalContext';

import Header from './components/Header';
import Body from './components/Body';

// Legacy
import FocusTrap from '../../../../components/FocusTrap';

interface ModalCompose {
  Header: typeof Header;
  Body: typeof Body;
}

interface BaseProps {
  onClose: () => void;
}

export interface ModalProps extends React.PropsWithChildren<BaseProps> {}

const Modal: FunctionComponent<ModalProps> & ModalCompose = ({
  onClose,
  children,
}) => (
  <Portal selector="portal-root">
    <ModalContext.Provider value={{ onClose }}>
      <Global styles={{ body: { overflow: 'hidden' } }} />
      <FocusTrap>
        <Flex
          onClick={() => onClose && onClose()}
          alignItems={['stretch', null, 'center']}
          justifyContent="center"
          py={[0, null, 10]}
          position="fixed"
          zIndex="popup"
          top="0"
          bottom="0"
          right="0"
          left="0"
          bg="rgba(50,50,50,0.8)"
          data-testid="MODAL_BACKGROUND"
        >
          <Flex
            onClick={(event: any) => event.stopPropagation()}
            maxHeight="100%"
            flexDirection="column"
            width={[1, null, '768px']}
            bg="white"
          >
            {children}
          </Flex>
        </Flex>
      </FocusTrap>
    </ModalContext.Provider>
  </Portal>
);

Modal.Header = Header;
Modal.Body = Body;

export default Modal;
