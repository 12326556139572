import PointsPlusPayComponent from '@/v2/components/ui/PointsPlusPay';
import { usePrice } from './PriceContext';
import useDefaultPoints from '@/hooks/pointsPay/useDefaultPoints';
import splitPointsPlusPayAmount from '@/v2/utils/splitPointsPlusPayAmount';
import { useMemo } from 'react';

export type PointsPlusPayProps = {
  compact?: boolean;
};
const PointsPlusPay = ({ compact }: PointsPlusPayProps) => {
  const {
    charges,
    tiers,
    offerId,
    travellers,
    displayTravellerTypes,
    showSummary,
  } = usePrice() || {};
  const amount = charges?.total?.amount;
  const { value: fractionAsPoints } = useDefaultPoints(
    offerId,
    charges?.total,
    tiers,
  );

  const { cash, points } = useMemo(() => {
    if (amount == null || !tiers) {
      return {
        cash: null,
        points: null,
      };
    }

    return splitPointsPlusPayAmount({
      amount,
      fractionAsPoints,
      tiers,
    });
  }, [amount, fractionAsPoints, tiers]);

  if (cash == null || points == null) {
    return null;
  }

  return (
    <PointsPlusPayComponent
      compact={compact}
      points={points}
      cash={cash}
      travellers={travellers}
      displayTravellerTypes={displayTravellerTypes}
      showSummary={showSummary}
    />
  );
};

export default PointsPlusPay;
