import accounting from 'accounting';
import { useState } from 'react';

const usePointsPayInput = (pointsPay) => {
  const [cash, setCash] = useState();
  const [cashFocused, setCashFocused] = useState(false);
  const [points, setPoints] = useState();
  const [pointsFocused, setPointsFocused] = useState(false);

  const createHandlers = (name) => {
    const stateSetter = {
      points: setPoints,
      cash: setCash,
    }[name];

    const pointsPaySetter = {
      points: pointsPay.setPoints,
      cash: pointsPay.setCash,
    }[name];

    const focusedSetter = {
      points: setPointsFocused,
      cash: setCashFocused,
    }[name];

    const precision = {
      points: 0,
      cash: 2,
    }[name];

    return {
      onFocus: () => {
        focusedSetter(true);
        stateSetter(accounting.unformat(pointsPay.state[name]));
      },
      onBlur: (event) => {
        stateSetter();
        focusedSetter(false);
        pointsPaySetter(parseFloat(event.target.value) || 0);
      },
      onChange: (event) => {
        const { value } = event.target;

        const parsed = value.replace(/[^\d.]/g, '');
        const parts = parsed.split('.');

        if (precision === 0 || parts.length === 1) {
          stateSetter(parts[0]);

          return;
        }

        stateSetter(`${parts[0]}.${parts[1].substr(0, precision)}`);
      },
    };
  };

  const {
    onFocus: onPointsFocus,
    onBlur: onPointsBlur,
    onChange: onPointsChange,
  } = createHandlers('points');

  const {
    onFocus: onCashFocus,
    onBlur: onCashBlur,
    onChange: onCashChange,
  } = createHandlers('cash');

  return {
    onPointsFocus,
    onPointsBlur,
    onPointsChange,
    points: pointsFocused
      ? points
      : accounting.formatNumber(pointsPay.state.points),
    onCashFocus,
    onCashBlur,
    onCashChange,
    cash: cashFocused ? cash : accounting.formatMoney(pointsPay.state.cash, ''),
  };
};

export default usePointsPayInput;
